<template>
  <div class="cases">
    <van-nav-bar
      title="精选案例"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      safe-area-inset-top
      :border="false"
    />
    <van-skeleton title :row="6" :loading="loading">
      <div class="search-btn-box" style="display:flex">
        <van-button
          style="flex:1;border:none;color:#696974"
          icon="arrow-down"
          icon-position="right"
          size="small"
          @click="show = true"
        >
          <div class="van-ellipsis btn-text">
            {{ active.toString() ? active.toString() : "全部风格" }}
          </div>
        </van-button>
      </div>
      <div class="content-main">
        <!-- <van-grid :column-num="2" :border="false">
          <van-grid-item v-for="item in caseList" :key="item">
            <case-item
              :case-info="item"
              class="case-item"
              @click="handleViewCaseDetails(item)"
            />
          </van-grid-item>
        </van-grid> -->
        <div class="goods-item-box" v-if="caseList.length > 0">
          <div class="goods-item" v-for="item in caseList" :key="item">
            <case-item
              :case-info="item"
              class="case-item"
              @click="handleViewCaseDetails(item)"
            />
          </div>
        </div>
        <div v-else class="nothing">
          暂无实景案例
        </div>
      </div>
    </van-skeleton>
    <bottom-tabbar />
    <van-popup
      round
      :show="show"
      :close-on-click-overlay="false"
      @click-overlay="null"
    >
      <div class="wrapper">
        <div>
          <div class="title">风格</div>
          <div style="margin-top:30px 0px">
            <span
              v-for="(item, index) in styleList"
              :key="index"
              :class="
                active.includes(item.dicName) ? 'active' : 'Classification'
              "
              @click="oncheck(item.dicName)"
            >
              {{ item.dicName }}</span
            >
          </div>
        </div>

        <div class="bottom-btns">
          <van-button block round style="flex:1" @click="handleResetSearch"
            >重置</van-button
          >
          <van-button
            block
            round
            style="flex:2"
            type="primary"
            @click="searchCaseList"
            >确定</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import CaseItem from "@/components/smallCaseItem.vue";
import { useStore } from "vuex";
import { getVideoStyleList, initVideoStyleInfo } from "../../api/common";
import { Toast } from "vant";
import BottomTabbar from "../../components/bottomTabbar.vue";

const router = useRouter();
const store = useStore();
const token = computed(() => store.getters.token);
const merchantId = computed(() => store.getters.merchantId);
const show = ref(false);
const loading = ref(true);
const active = ref([]);
const styleList = ref([]);
const caseList = ref([]);
const onClickLeft = () => {
  router.back();
};
const oncheck = (item) => {
  active.value.includes(item)
    ? active.value.splice(active.value.indexOf(item), 1)
    : active.value.push(item);
};
const initSelections = async () => {
  let data = await initVideoStyleInfo({ token: token.value });
  styleList.value = data;
};
const handleViewCaseDetails = (item) => {
  console.log(item);
  router.push({
    name: "caseDetails",
    query: {
      indexUrl: item.indexUrl,
      name: item.name,
      videoUrl: item.videoUrl,
    },
  });
};
const handleResetSearch = () => {
  active.value = [];
  show.value = false;
  searchCaseList();
};

const searchCaseList = async () => {
  Toast.loading({ duration: 300, message: "加载中" });
  loading.value = true;
  caseList.value = await getVideoStyleList({
    token: token.value,
    merchantId: merchantId.value,
    styleName: active.value.toString(),
  });
  show.value = false;
  loading.value = false;
  Toast.clear();
};

initSelections();
searchCaseList();
</script>

<style lang="scss" scoped>
.content-main {
  background-color: rgb(250, 250, 250);
  margin-bottom: 50px;
  padding: 0 12px;
  .nothing {
    font-size: 14px;
    color: #696974;
    padding: 40px 0;
    height: calc(100vh - 210px);
  }
  .goods-item-box {
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    .goods-item {
      margin-bottom: 9px;
    }
    .goods-item:nth-child(2n-1) {
      margin-right: 9px;
    }
  }
}

.wrapper {
  min-height: 200px;
  padding: 8px;
  width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    color: #171725;
    font-size: 14px;
  }
  .bottom-btns {
    display: flex;
    height: 50px;
  }
}
.active {
  float: left;
  margin-left: 10px;
  padding: 4px 12px;
  background: #d6e4ff;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
  color: #4d6ff0;
}
.Classification {
  float: left;
  margin-left: 10px;
  padding: 4px 12px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
}
.clear-float {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

.btn-text {
  width: 100px;
}
</style>
