<template>
  <div class="case-item-container" @click="handleViewCase">
    <div class="case-pic">
      <img v-if="caseInfo.indexUrl" :src="caseInfo.indexUrl" />
      <img
        v-else
        src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
      />
    </div>
    <div class="case-name  van-multi-ellipsis--l2">
      {{ caseInfo.name }}
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    caseInfo: Object,
  },
  setup(props) {
    const router = useRouter();
    const handleViewCase = () => {
      console.log("caseInfo============", props.caseInfo.vdId);
      router.push({
        name: "caseDetails",
        query: {
          indexUrl: props.caseInfo.indexUrl,
          name: props.caseInfo.name,
          videoUrl: props.caseInfo.videoUrl,
        },
      });
    };
    return {
      handleViewCase,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.case-item-container {
  text-align: left;
  background: #ffffff;
  width: 171px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

  .case-pic {
    img {
      width: 171px;
      height: 110px;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }
  }
  .case-name {
    color: #171725;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    padding: 8px 4px 18px 8px;
    height: 20px;
    line-height: 20px;
  }
}
</style>
